import {
    Autoformat,
    BlockQuote,
    Bold,
    ClassicEditor,
    Essentials,
    Heading,
    Indent,
    IndentBlock,
    Italic,
    Link,
    List,
    Mention,
    Paragraph,
    PasteFromOffice,
    TextTransformation,
    Underline
} from "ckeditor5";

export const setupCkEditor = async (targetElement, editor) => {
    if (editor === null || editor === undefined) {

        let element = document.querySelector(`#${targetElement}`);
        await ClassicEditor
            .create(element, {
                plugins: [
                    Autoformat,
                    BlockQuote,
                    Bold,
                    Essentials,
                    Heading,
                    Indent,
                    IndentBlock,
                    Italic,
                    Link,
                    List,
                    Mention,
                    Paragraph,
                    PasteFromOffice,
                    TextTransformation,
                    Underline,  /* ... */], // Plugins import.
                toolbar: [
                    'undo',
                    'redo',
                    '|',
                    'heading',
                    '|',
                    'bold',
                    'italic',
                    'underline',
                    '|',
                    'link',
                    'blockQuote',
                    '|',
                    'bulletedList',
                    'numberedList',
                    '|',
                    'outdent',
                    'indent',
                ],
                licenseKey: "Y3d4Uk41MjlBQWxxQVBuSzMxc3A1KzEwUmxnamVZRlJ0VWF3cHNyVVhBRDk1Sk5LdHJwSkRuVXpIVEdULU1qQXlOVEE0TWpZPQ==",
                language: 'da',
            })
            .then(newEditor => {
                editor = newEditor;
            })
            .catch();
    }

    return editor;
};