import {setupCkEditor} from "../Shared/SetupCkEditor";

let ckEditor = null;
let ckeEditorIsShown = false;

export const initCkEditor = () => {
    ckeEditorIsShown = false;

    if (ckEditor !== null){
        ckEditor.destroy();
        ckEditor = null;
    }
    
    $("#ckEditorBtn").off().on("click", async function() {
        await toggleCkEditor();
    });
}


export const showCkEditor = async () => {
    if (!ckeEditorIsShown) {
        ckeEditorIsShown = false; 
        await toggleCkEditor(); 
    }
}
export const hideCkEditor = async () => { ckeEditorIsShown = true; await toggleCkEditor(); }
export const toggleCkEditor = async () => {
    if (ckeEditorIsShown) {

        if (ckEditor !== null){
            $("#besvarfield").val(ckEditor.getData());
            ckEditor.destroy();
            ckEditor = null;
        }
        $("#ckEditorArea").hide();
        $("#textarea-container").show();
        $("#besvarfield").trigger($.Event('keyup', { keyCode: 35 }));
        $("#besvarfield").focus();
    } else {
        $("#ckEditorArea").show();
        $("#textarea-container").hide();

        if (ckEditor == null){
            ckEditor = await setupCkEditor("ckEditorArea");
        }
        ckEditor.setData($("#besvarfield").val());
        
        if ($("#outer-beskeder-container").length > 0) $("#outer-beskeder-container").css("padding-bottom", "285px")
        if ($("#inner-beskeder-container").length > 0) $("#inner-beskeder-container").scrollTop($("#inner-beskeder-container")[0].scrollHeight);
    }

    ckeEditorIsShown = !ckeEditorIsShown;
    toggleBtnPrimaryDefault();
}

const toggleBtnPrimaryDefault = () => {
    const btn = $("#ckEditorBtn");
    if (btn.hasClass("btn-primary")) {
        btn.removeClass("btn-primary");
        btn.addClass("btn-default");
    } else {
        btn.removeClass("btn-default");
        btn.addClass("btn-primary");
    }
}


export const getBesvarVal = () => {
    if (ckeEditorIsShown) {
        return ckEditor.getData();
    }
    else {
        return $("#besvarfield").val();
    }
}
export const setBesvarVal = (data: string) => {
    if (ckEditor !== null) {
        ckEditor.setData(data);
    }
     
    $("#besvarfield").val(data);
}

export const initBesvarField = () => {

    const innerbeskedContainer = $("#inner-beskeder-container");
    if (innerbeskedContainer.length === 0) return;
    innerbeskedContainer.scrollTop(innerbeskedContainer[0].scrollHeight);

    $("#besvarfield").off().on("keyup", function() {

        const scrollScrolltopBefore = innerbeskedContainer.scrollTop();
        const bottomHeightBeforeString = $("#outer-beskeder-container").css("padding-bottom");
        const bottomHeightBefore: number = parseInt(bottomHeightBeforeString, 10);

        const besvarField = document.getElementById("besvarfield");

        besvarField.style.height = '26px'; //Håndterer fjernelse af rækker i textarea
        besvarField.style.height = (besvarField.scrollHeight - 4) + 'px';
        if (+(besvarField.scrollHeight) <= 200) {
            const newBottomHeight = (besvarField.scrollHeight + 14);
            $("#outer-beskeder-container").css("padding-bottom", newBottomHeight + "px")

            innerbeskedContainer.scrollTop(scrollScrolltopBefore + newBottomHeight - (+bottomHeightBefore));
            $("#besvarfield").css("overflow-y", "hidden");
        } else {
            $("#besvarfield").css("overflow-y", "auto");
        }
    })
}
