export const initEkskluderFaellesUdd = (chkBoxId) => {


    let select = $("#select2").html('').select2();
    $("#" + chkBoxId).on("change", function () {
        showHideEkskluder(chkBoxId);
        console.log("yo")
    });

    $(".valgteUddannelse").each((i, e) => {
        let _id = $(e).data("id");
        let _text = $(e).data("text");
        let selected = $(e).data("selected").toString().toLowerCase() === "true";

        let newOption = new Option(_text, _id, selected, selected);
        select.append(newOption).trigger('change');
    })
    showHideEkskluder(chkBoxId);
}

export const showHideEkskluder = (chkBoxId) => {
    let checked = $(`#${chkBoxId}`).is(':checked');

    if (checked) {
        $("#faellesUddEkskludering").show()
    }
    else {
        $("#faellesUddEkskludering").hide()
    }
}